exports.components = {
  "component---src-pages-404-tsx": () => import("./../../../src/pages/404.tsx" /* webpackChunkName: "component---src-pages-404-tsx" */),
  "component---src-pages-about-tsx": () => import("./../../../src/pages/about.tsx" /* webpackChunkName: "component---src-pages-about-tsx" */),
  "component---src-pages-contact-tsx": () => import("./../../../src/pages/contact.tsx" /* webpackChunkName: "component---src-pages-contact-tsx" */),
  "component---src-pages-index-tsx": () => import("./../../../src/pages/index.tsx" /* webpackChunkName: "component---src-pages-index-tsx" */),
  "component---src-pages-pt-contacto-tsx": () => import("./../../../src/pages/pt/contacto.tsx" /* webpackChunkName: "component---src-pages-pt-contacto-tsx" */),
  "component---src-pages-pt-index-tsx": () => import("./../../../src/pages/pt/index.tsx" /* webpackChunkName: "component---src-pages-pt-index-tsx" */),
  "component---src-pages-pt-sobre-tsx": () => import("./../../../src/pages/pt/sobre.tsx" /* webpackChunkName: "component---src-pages-pt-sobre-tsx" */),
  "component---src-templates-blog-post-tsx": () => import("./../../../src/templates/blog-post.tsx" /* webpackChunkName: "component---src-templates-blog-post-tsx" */)
}

